export const useLogging = async (data: object, type: string) => {
  const authentificationStore = useAuthentificationStore();
  const vehiculeStore = useVehiculeStore();
  return await useMyFetch<string>('/copilot/vehicules/' + type, {
    method: 'POST',
    body: {
      idVehicule: vehiculeStore.getIdActif(),
      idEntite: vehiculeStore.getIdEntite(),
      ...data,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authentificationStore.getToken(),
    },
  });
};
