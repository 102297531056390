import {defineStore} from 'pinia';
import type {Fournisseur, ParametresRecherche} from '~/types/typesFournisseur';

export const useFournisseursStore = defineStore('fournisseurs', () => {
  const fournisseurRecommande = ref<Fournisseur | null>();
  const fournisseurFavori = ref<Fournisseur | null>();
  const listeFournisseurs = ref<Fournisseur[] | []>();
  const authentificationStore = useAuthentificationStore();
  const vehiculeStore = useVehiculeStore();
  const loaderStore = useLoaderStore();
  const toasterStore = useToasterStore();

  function $reset() {
    fournisseurRecommande.value = null;
    listeFournisseurs.value = [];
  }

  const setFournisseurFavori = (data?: Fournisseur) => {
    fournisseurFavori.value = data;
  };

  const setListeFournisseurs = async (data?: Fournisseur[]) => {
    listeFournisseurs.value = data;
  };

  const getFournisseurRecommande = computed(() => listeFournisseurs.value?.filter((f) => f.recommande)[0] ?? null);
  const getFournisseurFavori = computed(() => fournisseurFavori.value ?? null);
  const getListeFournisseurs = computed(() => listeFournisseurs.value?.filter((f) => !f.recommande) ?? []);

  const fetchFournisseurFavori = async () => {
    const {data, error} = await useMyFetch<Fournisseur>(
      '/copilot/vehicules/' + vehiculeStore.getIdActif() + '/favori',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
        params: {numeroDossier: vehiculeStore.getNumeroDossier()},
      },
    );
    if (data.value) {
      setFournisseurFavori({...data.value, urlLogo: await fetchLogoFournisseur(data.value.codeReseau)});
    } else if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurRecupFournisseurFavori');
    } else {
      setFournisseurFavori(undefined);
    }
  };
  const fetchFournisseurs = async (params: ParametresRecherche) => {
    loaderStore.showLoader();

    const {data, error} = await useMyFetch<Fournisseur[]>('/copilot/fournisseurs/appliConducteur', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
      params: {...params, idVehicule: vehiculeStore.getIdActif()},
    });

    if (data.value) {
      if (data.value.length === 0) {
        toasterStore.afficherLeToaster('error', 'messageErreur.erreurAucunFournisseur');
      } else {
        const fournisseursWithLogos = await Promise.all(
          data.value.map(async (f) => {
            const logo = await fetchLogoFournisseur(f.codeReseau);
            return {...f, urlLogo: logo};
          }),
        );
        setListeFournisseurs(fournisseursWithLogos);
        await useLogging(
          {
            action: 'RESULTATS_RECHERCHE_FOURNISSEURS',
            details: {
              prestation: params.prestations[0],
              latitudeUtilisateur: params.latitude,
              longitudeUtilisateur: params.longitude,
              idFournisseurRecommande: getFournisseurRecommande.value?.id,
              idsFournisseursAutorisesParRang: fournisseursWithLogos.reduce((acc, fournisseur, index) => {
                acc[index + 1] = fournisseur.id; // Utilise index + 1 comme clé
                return acc;
              }, {}),
            },
          },
          'logEventButtonClick',
        );
      }
    } else if (error.value) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurRecupFournisseurs');
    } else {
      setListeFournisseurs([]);
    }
    loaderStore.hideLoader();
  };

  const fetchLogoFournisseur = async (codeReseau: string) => {
    if (codeReseau) {
      const {data} = await useMyFetch<Blob>('/copilot/fournisseurs/logo', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authentificationStore.getToken(),
        },
        params: {codeReseau: codeReseau, idVehicule: vehiculeStore.getIdActif()},
        responseType: 'blob',
      });
      if (data.value?.size > 0) {
        return URL.createObjectURL(new Blob([data.value]));
      } else {
        return '/logo_app/cle.png';
      }
    }
  };

  const noterFournisseur = async (notationDTO: NotationDTO) => {
    loaderStore.showLoader();
    const {error} = await useMyFetch<string>('/copilot/fournisseurs/' + vehiculeStore.getIdActif() + '/insererNote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authentificationStore.getToken(),
      },
      body: notationDTO,
    });
    if (!error.value) {
      toasterStore.afficherLeToaster('success', 'messageSucces.fournisseurNote');
      useLogging(
        {
          action: 'NOTER_FOURNISSEUR',
          details: {
            idFournisseurChoisi: notationDTO.idFournisseur,
            note: notationDTO.note,
            commentaire: notationDTO.commentaire,
          },
        },
        'logEventButtonClick',
      );
    } else {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurNotationFournisseur');
    }
    loaderStore.hideLoader();
  };

  const ajouterFournisseurFavori = async (idFournisseur: string) => {
    loaderStore.showLoader();
    try {
      if (fournisseurFavori.value?.id === idFournisseur) {
        await useMyFetch<string>('/copilot/vehicules/' + vehiculeStore.getIdActif() + '/supprimerFournisseurFavori', {
          method: 'POST',
          body: {
            idFournisseur: idFournisseur,
            numeroDossier: vehiculeStore.getNumeroDossier(),
            createdAt: new Date(Date.now()),
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authentificationStore.getToken(),
          },
        });
      } else {
        await useMyFetch<string>('/copilot/vehicules/' + vehiculeStore.getIdActif() + '/ajouterFournisseurFavori', {
          method: 'POST',
          body: {
            idFournisseur: idFournisseur,
            numeroDossier: vehiculeStore.getNumeroDossier(),
            createdAt: new Date(Date.now()),
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authentificationStore.getToken(),
          },
        });
      }
      toasterStore.afficherLeToaster('success', 'messageSucces.fournisseurAjoute');
      useLogging(
        {
          action: 'AJOUTER_FOURNISSEUR_FAVORI',
          details: {
            idFournisseurChoisi: idFournisseur,
          },
        },
        'logEventButtonClick',
      );
    } catch (e) {
      toasterStore.afficherLeToaster('error', 'messageErreur.erreurAjoutFourisseurFavori');
    }

    fetchFournisseurFavori();
    loaderStore.hideLoader();
  };

  return {
    $reset,
    fetchFournisseurs,
    fetchFournisseurFavori,
    setFournisseurFavori,
    getFournisseurFavori,
    getFournisseurRecommande,
    getListeFournisseurs,
    noterFournisseur,
    ajouterFournisseurFavori,
  };
});
