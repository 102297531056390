export const transformTextToUnicodeSansAccents = (text: string) => {
  if (text) {
    return text
      .normalize('NFD') // Renvoie la forme normalisée Unicode d'une chaîne de caractères
      .replace(/[\u0300-\u036f]/g, ''); // Enleve les accents
  }

  return '';
};

export const transformTextToUnicodeAndSpaceToUnderscore = (text: string) => {
  return transformTextToUnicodeSansAccents(text).replace(/ /g, '_'); // Remplace tout les espaces par underscore
};

export const shouldMenuBeDifferent = (url: string) => {
  const urlPagesToIgnore = [
    '/documents/transmettre-document',
    '/documents',
    '/releve-kilometrique',
    '/prochaines-visites',
    '/tutoriel',
    '/mon-compte',
  ];
  return urlPagesToIgnore.includes(url);
};

export const normalizeNomPage = (nomPage: string) => {
  return nomPage
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f)(]/g, '')
    .replace(/[^A-Z]+/g, '_');
};

export const isEmptyObject = (object: object) => {
  if (Object.keys(object).length === 0 && object.constructor === Object) {
    return true;
  }

  return false;
};

// retourne l'écart en jours entre une date et aujourd'hui
export const diffDays = (date: Date) => {
  const today = new Date();
  const difference = new Date(date).getTime() - today.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

export const diffKilometrage = (dernierReleve: number, kilometrageCible: number) => {
  const difference = kilometrageCible - dernierReleve;
  return difference;
};

export const getVisites = (visites: Alerte[], vehicule: Vehicule) => {
  return visites.map((visite) => {
    let differenceKilometrage = null;
    let alerteKilometrage = null;
    if (visite.kilometrage) {
      differenceKilometrage = diffKilometrage(vehicule.dernierReleveKilometrique, visite.kilometrage);
      alerteKilometrage = differenceKilometrage < 0 ? 'error' : differenceKilometrage < 500 ? 'warning' : null;
    }

    const differenceDate = diffDays(visite.dateCible);
    const alerteDate = differenceDate < 0 ? 'error' : differenceDate < 60 ? 'warning' : null;
    let alerte;
    if (alerteKilometrage === 'error' || alerteDate === 'error') {
      alerte = 'error';
    } else if (alerteKilometrage === 'warning' || alerteDate === 'warning') {
      alerte = 'warning';
    } else {
      alerte = 'cloche';
    }

    return {
      ...visite,
      differenceDate,
      differenceKilometrage,
      alerteDate,
      alerteKilometrage,
      alerte,
    };
  });
};

export const getWorstWarning = (visites: object[]) => {
  if (visites) {
    let warning = null;
    let error = null;
    const visitesArray = Array.from(visites);
    visitesArray.forEach((visite) => {
      if (visite.alerteKilometrage === 'warning' || visite.alerteDate === 'warning') {
        warning = 'warning';
      }

      if (visite.alerteKilometrage === 'error' || visite.alerteDate === 'error') {
        error = 'error';
      }
    });

    if (error) {
      return error;
    }

    return warning;
  }

  return '';
};

export const transformStringToNumber = (string: string) => {
  switch (string) {
    case 'error':
      return 1;
    case 'warning':
      return 2;
    default:
      return 3;
  }
};

export const getHostName = () => {
  if (import.meta.client) {
    return window.location.hostname;
  }
};

export const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export const distanceInKmBetweenEarthCoordinates = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const earthRadiusKm = 6371;
  if (lat1 && lon1 && lat2 && lon2) {
    const diffRadiusLat = degreesToRadians(lat2 - lat1);
    const diffRadiusLon = degreesToRadians(lon2 - lon1);
    const radiusLat1 = degreesToRadians(lat1);
    const radiusLat2 = degreesToRadians(lat2);

    const a =
      Math.sin(diffRadiusLat / 2) * Math.sin(diffRadiusLat / 2) +
      Math.sin(diffRadiusLon / 2) * Math.sin(diffRadiusLon / 2) * Math.cos(radiusLat1) * Math.cos(radiusLat2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return (earthRadiusKm * c).toFixed(2).replace('.', ',') + ' km';
  }

  return null;
};

export const formatUrlLogoEntite = (url: string) => {
  if (url) {
    return 'data:image/gif;base64,' + url;
  }

  return null;
};

export const formatCamelCaseToUpperCase = (input: string) => {
  return input
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Ajoute un underscore entre les mots camelCase
    .toUpperCase(); // Transforme tout en majuscules
};
